import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import devops from "../images/schema-devops.png";
import back from "../images/back.svg";
import Wrapper from "../components/wrapper";

import Layout from "../components/layout";

const Agile = () => (
    <Layout>
        <Wrapper>
            <DevOps>
                <BackContainer>
                    {" "}
                    <Link style={{ display: "flex" }} to="/Competences/">
                        <img src={back} className="back" height="25px" />
                    </Link>
                    <h1>devOps</h1>
                </BackContainer>
                <div className="title-container ">
                    <h2 className="title-before">Des devOps intégrés aux développeurs</h2>
                </div>
                <p>
                    Nos DevOps semblent travailler dans l'ombre, mais ils sont toujours aux côtés de nos développeurs.
                    <br />
                    <br />
                    Ils sont là pour faciliter leur quotidien en apportant une expertise sur des points comme le
                    système, l’architecture, la sécurité et l'humour de barbus. Ils sont dans bien des cas des
                    conseillers en bonnes pratiques, prêts à aider la montée en compétence.
                    <br />
                    Ce sont eux qui vont mettre à disposition les outils du développeur, comme les serveurs de gestion
                    de version, de déploiement continu, d’audit de code, ou encore de communication.
                </p>
                <SchemaContainer>
                    <img src={devops} className="schema" alt="nombre" height="150px" />
                    <Caption>
                        <p>Schema d'intégration continue</p>
                    </Caption>
                </SchemaContainer>
                <p>
                    Les DevOps mettent en place les environnements d’intégration, de recette, de pré-production et de
                    production.
                    <br />
                    Mais c'est eux aussi qui veillent au bon respect du process de cette chaîne de déploiement (les
                    développeurs ayants de mauvaises pratiques sont bien entendu abattus à vue).
                    <br />
                    Ils sont d'abord des administrateurs systèmes : ils ont aussi la charge de la disponibilité des
                    applicatifs par l'attente à leur performance, la supervision, l'inspection des logs et de la
                    sécurité des données via des backups réguliers.
                    <br />
                    <br />
                    Et grâce à une veille technologique constante, les dernières failles de sécurité sont corrigées dans
                    des délais très courts. La haute disponibilité étant aujourd'hui une priorité, nos DevOps sont
                    capables de mettre en place des architectures résilientes dans touts leurs aspects : bases de
                    données, systèmes des fichiers, applicatifs, pour atteindre aisément un taux de disponibilité de
                    99.9%
                </p>
            </DevOps>
        </Wrapper>
    </Layout>
);
const DevOps = styled.div`
    @media screen and (min-width: 992px) {
        width: 75%;
    }
    margin-bottom: 60px;
`;
const SchemaContainer = styled.div`
    margin-top: 60px;
    margin-bottom: 60px;
`;
const Caption = styled.div`
    font-size: 12px;
`;

const BackContainer = styled.div`
    display: flex;
    align-items: center;
    .back {
        margin-right: 15px;
    }
`;

export default Agile;
